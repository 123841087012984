import { Box, Checkbox, CircularProgress, Container, Dialog, FormControlLabel, FormGroup, Link, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react";
import { SessionContext } from "src/context/SessionContext";
import Check from '@mui/icons-material/CheckRounded';
import WarningAmber from '@mui/icons-material/WarningAmberRounded';
import MailOutline from '@mui/icons-material/MailOutlineRounded';
import LocationOn from '@mui/icons-material/LocationOnRounded';
import PhoneRound from '@mui/icons-material/PhoneRounded';
import ArrowRight from '@mui/icons-material/KeyboardArrowRightRounded';
import ArrowLeft from '@mui/icons-material/ArrowBackIosRounded';
import CheckCircle from '@mui/icons-material/CheckCircleOutlineRounded';
import Lock from '@mui/icons-material/LockRounded';
import EyeOutlined from '@mui/icons-material/RemoveRedEyeOutlined';
import DocIcon from '@mui/icons-material/InsertDriveFileOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { StyledTableCell, StyledTableRow } from "src/components/enrollment/coi";
import { ReactComponent as UploadRenter } from 'src/svg/upload-renter.svg';
import { ReactComponent as AppStore } from '../../svg/appstore.svg';
import { ReactComponent as PlayStore } from '../../svg/playstore.svg';
import { useDropzone } from "react-dropzone";
import { DateTime } from 'luxon';
import { VendorProfile } from "./vendor-profile";
import { VendorMessages } from "./vendor-messages";
import { useIntercom } from "src/utils/intercom";
import { useIsMobile, useIsTab } from "src/utils/useIsMobile";
import ButtonCustom from "src/components/button";
import api, { checkInsureproCOI } from "src/api";
import { saveInsuranceInfo, formatBytes } from "src/utils/helpers";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import SaveAndContinue from "src/components/save-and-continue";
import { useSearchParams } from "react-router-dom";
import PaymentStatus from "src/components/enrollment/payment/status";

function isWrokersCom(riskLevel: string, numEmployees: number, address: string) {
	const addressComponents = address.split(',');
	const state = addressComponents.length > 2 ? addressComponents[addressComponents.length - 2] : '';
	if (riskLevel === 'HIGH' && numEmployees >= 4) {
		return true;
	} else if (riskLevel === 'HIGH') {
		return false;
	} else if (
		(riskLevel === 'LOW' && (state === 'FL' || state === 'SC') && numEmployees >= 4) ||
		(state === 'GA' && numEmployees >= 3) ||
		(state === 'AZ' && numEmployees >= 1) ||
		state === 'IL' ||
		state === 'OK' ||
		state === 'CO' ||
		state === 'UT'
	) {
		return true;
	} else if (riskLevel === 'LOW') {
		return false;
	} else if (riskLevel === 'NONE') {
		return false;
	}
	return false;
}

export const NewDashboard = ({ enrollmentStatus, coiChecks, vendorDetails, coiFiles, insurances, reloadVendorDetails, showInsurepro }: any) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [paymentPopup, setPaymentPopup] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState('');

    const { riskLevel } = useContext(SessionContext);

    const [showDetails, setShowDetails] = useState(null as any);

    const [editProfile, setEditProfile] = useState(false);
    const [showMessages, setShowMessages] = useState(false);
    const [showUploadNewFile, setShowUploadNewFile] = useState(false);

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const [popoverText, setPopoverText] = useState('');
    const [popoverTitle, setPopoverTitle] = useState('');

    const isMobile = useIsMobile();
    const isTab = useIsTab();

    const handleClick = (event: any, option: any) => {
        setAnchorEl(event.currentTarget);
        switch(option) {
            case '1':
                setPopoverTitle('Primary and Non-contributory');
                setPopoverText('Ensures that your insurance policy is the first line of defense in the event of a claim. It means that if other insurance policies are involved, yours will be the first to pay out and won\'t have to contribute to any other policies\' payouts.');
                break;
            case '2':
                setPopoverTitle('Additional Insured');
                setPopoverText('Provides broad coverage to any person or entity that you may work with in the course of your business operations. It can save you time and money by not having to add individual parties to your policy as additional insureds. This can be useful for businesses that work with a wide range of vendors, contractors, or other entities on a regular basis.');
                break;
            case '3':
                setPopoverTitle('Waiver of Subrogation');
                setPopoverText('An agreement that stops an insurance company from trying to get back the money they paid for a claim from someone who caused the damage. An Owner Client may require this endorsement from their vendors to avoid being held liable for claims that occur on their job site.');
                break;
            case '4':
                setPopoverTitle('30 Days Notice of Cancellation');
                setPopoverText('Provides you with a 30-day notice if your insurance policy is going to be cancelled by the insurance company. This can help you avoid any lapses in coverage and give you time to find a new insurance provider if needed.');
                break;
        }
    };

    useEffect(() => {
        if(searchParams.get('success') !== null) {
            setPaymentStatus(searchParams.get('success') || '');
            setPaymentPopup(true);
        }
    }, [searchParams]);
    
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const open = Boolean(anchorEl);

    const {run} = useIntercom();

    const [fileUploading, setFileUploading] = useState(false);

    const onDrop = useCallback(async(files: File[]) => {
        console.log(files);
        files.forEach(f => {
            setFileUploading(true);
			api.getCOIUploadUrl(vendorDetails?._id, f.name).then(url => {
				fetch(url, {
					method: 'PUT',
					body: f,
					headers: {
						'Content-Type': f.type,
					},
				}).then(async (value) => {
                    try {
                        for (const file of files) {
                            const r1 = await checkInsureproCOI(file);
                            await saveInsuranceInfo(r1, vendorDetails?._id);
                        }
                    } catch(e) {
                        console.log(e)

                    } finally {
                        setFileUploading(false);
                        reloadVendorDetails();
                        setShowUploadNewFile(false);
                        api.coiUploadNotification(vendorDetails?._id);
                    }
				});
			});
		});
    }, []);

    const { fileRejections, getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
          'application/pdf': [],
          'image/jpeg': [],
          'image/png': [],
        },
        maxFiles: 1,
        multiple: false,
      });

    const cards = [
        { id: 'GL', name: 'General Liability', cDescription: 'General liability insurance provides financial protection to business owners in case they are held legally responsible for bodily injury, property damage, or personal injury caused to others as a result of their business operations.' }, 
        { id: 'WC', name: 'Workers Comp', cDescription: 'Workers\' compensation insurance provides financial and medical benefits to employees who are injured or become ill during the course of their employment, typically covering medical expenses, lost wages, and rehabilitation costs.' }, 
        { id: 'CA', name: 'Auto Liability', cDescription: 'Auto liability insurance provides financial protection for the insured party in case they are found legally responsible for causing injury or damage to another person or their property while operating a vehicle.' }, 
        { id: 'EL', name: 'Excess Liability', cDescription: 'Excess liability insurance, also known as umbrella insurance, provides additional coverage beyond the limits of primary liability policies, offering an extra layer of protection against catastrophic losses or lawsuits that exceed the underlying policy limits.' }, 
        { id: 'PY', name: 'Payment Account', cDescription: '' }, 
        { id: 'MVA', name: 'Accept MVA', cDescription: 'Please review and sign the terms and conditions of ResProp Managment Company.' },
        { id: 'CF', name: 'Compliance Fee', cDescription: 'This annual compliance fee is used for your background check as well as keeping your account active in IDcore\'s system.' }
    ];
    const descriptions = {
        missingLimit: 'Your coverage limits do not meet the requirements.' ,
        missingEndorsement: 'You are missing some necessary endorsements on this policy.' ,
        missingPolicy: 'You are missing coverage on this policy.' ,
        policyExpirted: 'Your policy has expired and is not valid anymore.' ,
        policyValid: 'You meet all the requirements.' ,
        paymentDisabled: 'You need to be compliant before creating a payment processing account for Resprop employment.',
        paymentMissing: 'ResProp requires vendors to register with their payment processor in order to get paid.' ,
        paymentSuccess: 'Your payment account with PayUp has been set up.' ,
        feeNeeded: 'Pay the annual fee to activate your account on ID core.',
        feeExpired: 'You need to pay the annual compliance fee before you can get more jobs.' ,
        feeComplete: 'Your compliance Fee has been paid and will renew on ',
        feeExpiring: 'Your compliance Fee will expire on FEE_EXPIRING_DATE. Pay before to avoid a freeze on your account.',
        policyNotNeeded: 'This coverage is not needed for your service type',
        mvaMissing: 'You need to sign the master vendor agreement for the ResProp Management Company',
        mvaAccepted: 'You have signed the MVA'
    };

    const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		maximumFractionDigits: 0,
	});

    const getCompliaceStyles = (card: any) => {
        const rObj: any = {...card, color: '#272937BF', background: '#D1D1D1', status: 'DISABLED', description: descriptions.paymentDisabled}
        switch (card.id) {
            case 'GL':
                if(riskLevel === 'NONE') {
                    rObj.color = '#014D40';
                    rObj.background = '#C6F7E2';
                    rObj.description = descriptions.policyNotNeeded;
                    rObj.status = 'COMPLIANT';
                }
                else {
                    if(insurances && insurances.generalLiability && insurances.generalLiability.status === 'COMPLIANT') {
                        rObj.color = '#014D40';
                        rObj.background = '#C6F7E2';
                        rObj.description = descriptions.policyValid;
                    } else if(insurances && insurances.generalLiability && insurances.generalLiability.status === 'MISSINGEND') {
                        rObj.color = '#610316';
                        rObj.background = '#FFBDBD';
                        rObj.description = descriptions.missingEndorsement;
                    } else if(insurances && insurances.generalLiability && insurances.generalLiability.status === 'NON-COMPLIANT') {
                        rObj.color = '#610316';
                        rObj.background = '#FFBDBD';
                        rObj.description = descriptions.missingLimit;
                    } else {
                        rObj.color = '#610316';
                        rObj.background = '#FFBDBD';
                        rObj.description = insurances.generalLiability && insurances.generalLiability.status !== 'MISSING' ? descriptions.missingEndorsement : descriptions.missingPolicy;
                    }
                    rObj.status = insurances?.generalLiability?.status ?? 'NON-COMPLIANT';
                    rObj.insurance = insurances?.generalLiability;
                }
                break;
            case 'WC':
                if(riskLevel === 'NONE') {
                    rObj.color = '#014D40';
                    rObj.background = '#C6F7E2';
                    rObj.description = descriptions.policyNotNeeded;
                    rObj.status = 'COMPLIANT';
                }
                else {
                    if(insurances && insurances.workerPolicy && insurances.workerPolicy.status === 'COMPLIANT') {
                        rObj.color = '#014D40';
                        rObj.background = '#C6F7E2';
                        rObj.description = descriptions.policyValid;
                    } else if(insurances && insurances.workerPolicy && insurances.workerPolicy.status === 'MISSINGEND') {
                        rObj.color = '#610316';
                        rObj.background = '#FFBDBD';
                        rObj.description = descriptions.missingEndorsement;
                    } else if(insurances && insurances.workerPolicy && insurances.workerPolicy.status === 'NON-COMPLIANT') {
                        rObj.color = '#610316';
                        rObj.background = '#FFBDBD';
                        rObj.description = descriptions.missingLimit;
                    } else {
                        rObj.color = '#610316';
                        rObj.background = '#FFBDBD';
                        rObj.description = insurances.workerPolicy && insurances.workerPolicy.status !== 'MISSING' ? descriptions.missingEndorsement : descriptions.missingPolicy;
                    }
                    rObj.status = insurances?.workerPolicy?.status ?? 'NON-COMPLIANT';
                    rObj.insurance = insurances?.workerPolicy;
                }
                break;
            case 'CA':
                if(riskLevel === 'NONE') {
                    rObj.color = '#014D40';
                    rObj.background = '#C6F7E2';
                    rObj.description = descriptions.policyNotNeeded;
                    rObj.status = 'COMPLIANT';
                }
                else {
                    if(insurances && insurances.autoLiability && insurances.autoLiability.status === 'COMPLIANT') {
                        rObj.color = '#014D40';
                        rObj.background = '#C6F7E2';
                        rObj.description = descriptions.policyValid;
                    } else if(insurances && insurances.autoLiability && insurances.autoLiability.status === 'MISSINGEND') {
                        rObj.color = '#610316';
                        rObj.background = '#FFBDBD';
                        rObj.description = descriptions.missingEndorsement;
                    } else if(insurances && insurances.autoLiability && insurances.autoLiability.status === 'NON-COMPLIANT') {
                        rObj.color = '#610316';
                        rObj.background = '#FFBDBD';
                        rObj.description = descriptions.missingLimit;
                    } else {
                        rObj.color = '#610316';
                        rObj.background = '#FFBDBD';
                        rObj.description = insurances.autoLiability && insurances.autoLiability.status !== 'MISSING' ? descriptions.missingEndorsement : descriptions.missingPolicy;
                    }
                    rObj.status = insurances?.autoLiability?.status ?? 'NON-COMPLIANT';
                    rObj.insurance = insurances?.autoLiability;
                }
                break;
            case 'EL':
                if(riskLevel === 'NONE') {
                    rObj.color = '#014D40';
                    rObj.background = '#C6F7E2';
                    rObj.description = descriptions.policyNotNeeded;
                    rObj.status = 'COMPLIANT';
                }
                else {
                    if(insurances && insurances.excessLiability && insurances.excessLiability.status === 'COMPLIANT') {
                        rObj.color = '#014D40';
                        rObj.background = '#C6F7E2';
                        rObj.description = descriptions.policyValid;
                    } else if(insurances && insurances.excessLiability && insurances.excessLiability.status === 'MISSINGEND') {
                        rObj.color = '#610316';
                        rObj.background = '#FFBDBD';
                        rObj.description = descriptions.missingEndorsement;
                    } else if(insurances && insurances.excessLiability && insurances.excessLiability.status === 'NON-COMPLIANT') {
                        rObj.color = '#610316';
                        rObj.background = '#FFBDBD';
                        rObj.description = descriptions.missingLimit;
                    } else {
                        rObj.color = '#610316';
                        rObj.background = '#FFBDBD';
                        rObj.description = insurances.excessLiability && insurances.excessLiability.status !== 'MISSING' ? descriptions.missingEndorsement : descriptions.missingPolicy;
                    }
                    rObj.status = insurances?.excessLiability?.status ?? 'NON-COMPLIANT';
                    rObj.insurance = insurances?.excessLiability;
                }
                break;
            case 'PY':
                if(vendorDetails?.overallComplianceStatus === 'COMPLIANT') {
                    if(vendorDetails?.isPayupRegistered) {
                        rObj.color = '#014D40';
                        rObj.background = '#C6F7E2';
                        rObj.description = descriptions.paymentSuccess;
                        rObj.status = 'COMPLIANT';
                        rObj.cDescription = 'We found and linked your PayUp account. no further action is needed';
                    } else {
                        rObj.color = '#610316';
                        rObj.background = '#FFBDBD';
                        rObj.description = descriptions.paymentMissing;
                        rObj.status = 'NON-COMPLIANT';
                        rObj.cDescription = 'We couldn\'t locate a Payup account that is associated with your business. This payment account creation is required before Resprop management can hire you for jobs. ';
                    }
                } else {

                }
                break;
            case 'CF':
                const dt = new Date(vendorDetails?.payment?.paymentDate);
                const dt1 = DateTime.fromJSDate(dt).plus({years: 1});
                const dtDiff = dt1.diffNow('minutes').minutes;
                rObj.color = '#014D40';
                rObj.background = '#C6F7E2';
                if(!vendorDetails?.payment || !vendorDetails?.payment?.paymentDate) {
                    rObj.color = '#610316';
                    rObj.background = '#FFBDBD';
                    rObj.description = descriptions.feeNeeded;
                    rObj.status = 'MISSING';  
                    rObj.title1 = 'Payment is needed';
                    rObj.description1 = descriptions.feeNeeded;
                    rObj.btnTxt = 'Pay Annual Fee';
                }
                else if(dtDiff >= 0) {
                    if(dtDiff <= 43800) {
                        rObj.description = descriptions.feeExpiring.replace('FEE_EXPIRING_DATE', dt1.toFormat('MM/dd/yyyy'));
                        rObj.status = 'EXPIRING';  
                        rObj.title1 = 'Expiring on ' + dt1.toFormat('MM/dd/yyyy');
                        rObj.description1 = 'Pay before this date to avoid a freeze on your account';
                        rObj.btnTxt = 'Pay Annual Fee';
                    } else {
                        rObj.description = descriptions.feeComplete + dt1.toFormat('MM/dd/yyyy');
                        rObj.status = 'COMPLIANT';
                        rObj.title1 = 'Paid on ' + DateTime.fromJSDate(dt).toFormat('MM/dd/yyyy');
                        rObj.description1 = '';
                        rObj.btnTxt = '';
                    }
                } else {
                    rObj.color = '#610316';
                    rObj.background = '#FFBDBD';
                    rObj.description = descriptions.feeExpired;
                    rObj.status = 'NON-COMPLIANT';
                    rObj.title1 = 'Expired on ' + dt1.toFormat('MM/dd/yyyy');
                    rObj.description1 = 'Your account has been placed on hold.';
                    rObj.btnTxt = 'Pay Annual Fee';
                }
                break;
            case 'MVA':
                if (vendorDetails?.msaResprop) {
                    rObj.color = '#014D40';
                    rObj.background = '#C6F7E2';
                    rObj.description = descriptions.mvaAccepted;
                    rObj.status = 'COMPLIANT';  
                } else {
                    rObj.color = '#610316';
                    rObj.background = '#FFBDBD';
                    rObj.description = descriptions.mvaMissing;
                    rObj.status = 'MISSING';  
                }
        }
        return rObj;
    }

    const [formValues, setFormValues] = useState({
		masterServiceTerm: vendorDetails?.msaResprop ?? false,
	});

	//  Form valication
	const validationSchema = Yup.object().shape({
		masterServiceTerm: Yup.bool().oneOf([true], 'Checkbox selection is required'),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(validationSchema),
	});

	const handleChange = (event: any, attributeName?: string, MUIvalue?: any) => {
		event.persist();

		setFormValues((values: any) => ({
			...values,
			[event.target.name || attributeName]: MUIvalue !== undefined ? MUIvalue : event.target.value,
		}));
	};

    const goNext = () => {
		api
			.acceptRespropMsa(vendorDetails._id)
			.then(async() => {
                reloadVendorDetails();
                if(riskLevel === 'NONE') {
                    await api.updateNoRiskVendor(vendorDetails._id);
                }
				setShowDetails(false);
			})
			.catch(err => {
				console.error(err);
			});
	};

    return <>
        {!showDetails && <Container>
            <Box sx={{marginTop: '24px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', rowGap: '8px'}}>
                <Typography sx={{color: '#000', fontSize: '32px', fontWeight: 500, lineHeight: '36px'}}>{vendorDetails.w9.businessName}</Typography>
                <Box sx={{padding: '8px 24px', borderRadius: '4px', background: enrollmentStatus === 'COMPLIANT' ? '#C6F7E2' : enrollmentStatus === 'PENDING' ? '#FFF3C4' : '#FFBDBD'}}>
                    <Typography sx={{color: enrollmentStatus === 'COMPLIANT' ? '#0C6B58' : enrollmentStatus === 'PENDING' ? '#8D2B0B' : '#8A041A', fontSize: '20px', fontWeight: 600, lineHeight: '24px'}}>{enrollmentStatus}</Typography>
                </Box>
            </Box>
            <Typography sx={{color: '#0B69A3', fontSize: '16px', fontWeight: 500, lineHeight: '24px', marginTop: '12px'}}>Reference Number: {vendorDetails.referenceId}</Typography>
            <Box sx={{marginTop: '12px', display: 'flex', flexDirection: 'row', gap: '12px'}}>
                <Box sx={{padding: '4px 16px', borderRadius: '4px', background: '#D9E2EC'}}>
                    <Typography sx={{color: '#334E68', fontSize: '14px', fontWeight: 700, lineHeight: '20px'}}>{riskLevel} RISK VENDOR</Typography>
                </Box>
                {vendorDetails.w9.tinType !== 'SSN' && <Box sx={{padding: '4px 8px 4px 16px', borderRadius: '4px', background: vendorDetails.tinCheck === 'COMPLIANT' ? '#C6F7E2' : vendorDetails.tinCheck === 'PENDING' ? '#FFF3C4' : '#FFBDBD', display: 'flex', flexDirection: 'row', gap: '8px', color: vendorDetails.tinCheck === 'COMPLIANT' ? '#0C6B58' : vendorDetails.tinCheck === 'PENDING' ? '#8D2B0B' : '#8A041A'}}>
                    <Typography sx={{color: vendorDetails.tinCheck === 'COMPLIANT' ? '#0C6B58' : vendorDetails.tinCheck === 'PENDING' ? '#8D2B0B' : '#8A041A',fontSize: '14px', fontWeight: 700, lineHeight: '20px'}}>TIN CHECK {vendorDetails.tinCheck === 'PENDING' ? '(PENDING)' : ''}</Typography>{vendorDetails.tinCheck === 'COMPLIANT' ? <Check sx={{height: '20px', width: '20px'}}/> : vendorDetails.tinCheck === 'NON-COMPLIANT' ? <WarningAmber sx={{height: '20px', width: '20px'}}/> : <></>}
                </Box>}
                {vendorDetails.w9.tinType === 'SSN' && <Box sx={{padding: '4px 8px 4px 16px', borderRadius: '4px', background: '#FFBDBD', display: 'flex', flexDirection: 'row', gap: '8px'}}>
                    <Typography sx={{color: '#000', fontSize: '14px', fontWeight: 700, lineHeight: '20px'}}>Background Check</Typography><WarningAmber sx={{height: '20px', width: '20px'}}/>
                </Box>}
            </Box>
            <Box sx={{marginTop: '24px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', rowGap: '8px'}}>
                <Box sx={{display: 'flex', flexDirection: 'row', gap: '16px', color: '#486581'}}>
                    <MailOutline sx={{width: '24px', height: '24px'}}/>
                    <Typography sx={{color: '#272937', fontSize: '20px', lineHeight: '28px'}}>{vendorDetails.bizInfo.email}</Typography>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row', gap: '16px', color: '#486581', maxWidth: '360px'}}>
                    <LocationOn sx={{width: '24px', height: '24px'}}/>
                    <Typography sx={{color: '#272937', fontSize: '20px', lineHeight: '28px'}}>{vendorDetails.w9.address}</Typography>
                </Box>
            </Box>
            <Box sx={{marginTop: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', flexDirection: 'row', gap: '16px', color: '#486581'}}>
                    <PhoneRound sx={{width: '24px', height: '24px'}}/>
                    <Typography sx={{color: '#272937', fontSize: '20px', lineHeight: '28px'}}>{vendorDetails.bizInfo.phone}</Typography>
                </Box>
            </Box>
            <Box sx={{marginTop: '32px', display: 'flex', flexDirection: 'row', gap: '24px', flexWrap: 'wrap', rowGap: '8px'}}>
                <Box sx={{padding: '12px 20px', display: 'flex', justifyContent: 'center', alignItems:'center', borderRadius: '8px', border: '2px solid #243B53', background: '#F1F5F8', cursor: 'pointer'}} onClick={() => setEditProfile(true)}>
                    <Typography sx={{color: '#243B53', fontSize: '16px', lineHeight: '24px', fontWeight: 600}}>Edit Profile</Typography>
                </Box>
                <Box sx={{padding: '12px 32px', display: 'flex', justifyContent: 'center', alignItems:'center', borderRadius: '8px', background: '#243B53', cursor: 'pointer'}} onClick={() => setShowMessages(true)}>
                    <Typography sx={{color: '#fff', fontSize: '16px', lineHeight: '24px', fontWeight: 600}}>View Messages</Typography>
                </Box>
            </Box>
            <Box sx={{marginTop: '80px', display: 'flex', flexDirection: 'column', gap: '24px'}}>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', rowGap: '8px'}}>
                    <Typography sx={{color: '#0D0D0D', fontSize: '24px', lineHeight: '28px'}}>Compliance Center</Typography>
                    {riskLevel !== 'NONE' && <Link sx={{paddingRight: '32px'}} href={
                            riskLevel === 'HIGH'
                                ? 'https://www.respropmanagement.com/_files/ugd/2d056e_d38cf981373848d2bd1332efdba0a455.pdf'
								: 'https://www.respropmanagement.com/_files/ugd/2d056e_5fbfcd191f1e4ee495cd568bad1301db.pdf'
                        } target="_blank">
                        <Box sx={{display: 'flex', flexDirection: 'row', gap: '10px', color: '#0B69A3', cursor: 'pointer'}}>
                            <EyeOutlined sx={{width: '24px', height: '24px'}} />
                            <Typography sx={{fontSize: '16px', lineHeight: '24px', fontWeight: 500, color: '#0B69A3'}}>View Sample COI</Typography>
                        </Box>
                    </Link>}
                </Box>
                {showInsurepro && <Box>
                    <Link href='https://web.insurepro.com'>Click Here</Link> or Call <Link href={'tel:+15128279990'}>(512) 827 9990</Link> to get free Insurance Quote From InsurePro and get compliant now</Box>}
                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '32px'}}>
                    {(riskLevel === 'NONE' ? cards.filter(c => c.id !== 'CF') : cards).map((c) => <>
                        {((c.id === 'WC' && isWrokersCom(riskLevel, vendorDetails?.bizInfo?.numEmployees ?? 0, vendorDetails?.w9?.address ?? '')) || c.id !== 'WC') ?
                        <>
                        {((c.id === 'EL' && riskLevel === 'HIGH') || c.id !== 'EL') ? 
                        <Box key={c.id} sx={{width: '100%', maxWidth: isMobile ? '100%' : '233px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', boxShadow: '0px 6px 24px 0px rgba(39, 41, 55, 0.10)', borderRadius: '8px'}}>
                            <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', background: '#fff', paddingX: '24px',  alignItems: 'center', minHeight: '265px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px'}}>
                                <Box sx={{marginTop: '24px', color: getCompliaceStyles(c).color, background: getCompliaceStyles(c).background, padding: '8px', borderRadius: '50%', height: '48px', width: '48px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    {getCompliaceStyles(c).status === 'COMPLIANT' ? <CheckCircle sx={{width: '24px', height: '24px'}}/> : getCompliaceStyles(c).status === 'DISABLED' ? <Lock sx={{width: '24px', height: '24px'}}/> :  <WarningAmber sx={{width: '24px', height: '24px'}}/>}
                                </Box>
                                <Box sx={{marginTop: '24px'}}>
                                    <Typography sx={{fontSize: '20px', lineHeight: '28px', fontWeight: 600}}>{c.name}</Typography>
                                </Box>
                                <Box sx={{marginTop: '12px'}}>
                                    <Typography sx={{color: '#272937', fontSize: '16px', lineHeight: '24px', textAlign: 'center'}}>{getCompliaceStyles(c).description}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{paddingY: '12px', display: 'flex', flexDirection: 'row', gap: '4px', color: getCompliaceStyles(c).status !== 'DISABLED' ? (riskLevel === 'NONE' && c.id !== 'PY' && c.id !== 'MVA') ? '#27293759' : '#102A43' : '#27293759', background: '#F0F4F8', cursor: getCompliaceStyles(c).status === 'DISABLED' || (riskLevel === 'NONE' && c.id !== 'PY' && c.id !== 'MVA') ? 'default' : 'pointer'}} onClick={() => {
                                if(getCompliaceStyles(c).status !== 'DISABLED') {
                                    if(riskLevel === 'NONE' && c.id !== 'PY' && c.id !== 'MVA') {
                                    } else {
                                        setShowDetails(getCompliaceStyles(c));
                                    }
                                }
                                    
                            }}>
                                <Typography sx={{color: getCompliaceStyles(c).status !== 'DISABLED' ? (riskLevel === 'NONE' && c.id !== 'PY' && c.id !== 'MVA') ? '#27293759' : '#102A43' : '#27293759', fontSize: '16px', fontWeight: 600, lineHeight: '24px'}}>{getCompliaceStyles(c).status !== 'DISABLED' ? 'View Details' : 'Locked'}</Typography><ArrowRight sx={{width: '24px', height: '24px'}}/>
                            </Box>
                        </Box> : <></> } </>: <></>}
                    </>)}
                    <Box sx={{maxWidth: '498px', borderRadius: '8px', background: 'rgba(255, 255, 255, 0.20)', boxShadow: '0px 3px 4px 0px rgba(36, 59, 83, 0.08), 0px 4px 6px 0px rgba(16, 42, 67, 0.06) inset'}}>
                        <Box sx={{padding: '32px'}}>
                            <Box>
                                <Typography sx={{color: '#272937', fontSize: '20px', lineHeight: '28px', fontWeight: 600}}>Get Affordable business insurance with InsurePro</Typography>
                            </Box>
                            <Box sx={{marginTop: '16px'}}>
                                <Typography sx={{color: '#272937', fontSize: '16px', lineHeight: '24px'}}>Quickly compare rates from 20+ carriers to find the best insurance plan for you.</Typography>
                            </Box>
                            <Box sx={{marginTop: '24px', display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center', verticalAlign: 'middle', flexWrap: 'wrap', rowGap: '8px'}}>
                                <Typography sx={{color: '#6E6E6E', fontSize: '14px', lineHeight: '20px', maxWidth: '200px'}}>Some of the Carriers We Quote With</Typography>
                                <Box sx={{height: '30px', width: '1px', background: '#BCCCDC'}}></Box>
                                <Box sx={{display: 'flex', flexDirection: 'row', gap: '12px'}}>
                                    <Box sx={{width: '30px', height: '30px'}}><img src="/liberty-c.png" width={'30px'}/></Box>
                                    <Box sx={{width: '30px', height: '30px'}}><img src="/nationwide-c.png" width={'30px'}/></Box>
                                    <Box sx={{width: '30px', height: '30px'}}><img src="/travellers-c.png" width={'30px'}/></Box>
                                    <Box sx={{width: '30px', height: '30px'}}><img src="/progressive-c.png" width={'30px'}/></Box>
                                    <Box sx={{width: '30px', height: '30px'}}><img src="/employers-c.png" width={'30px'}/></Box>
                                </Box>
                            </Box>
                            <Box sx={{marginTop: '18px', padding: '10px 32px', justifyContent: 'center', alignItems :'center', display: 'flex', background: '#F4F7F9', border: '2px solid #243B53', borderRadius: '8px', cursor: 'pointer'}} >
                                <Link href='https://web.insurepro.com'>
                                    <Typography sx={{color: '#243B53', fontSize: '16px', lineHeight: '24px', fontWeight: 600}}>Get Covered</Typography>
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{marginTop: '80px', display: 'flex', flexDirection: 'column', gap: '24px'}}>
                <Typography sx={{color: '#0D0D0D', fontSize: '24px', lineHeight: '32px'}}>Your Document(s) on File</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{color: '#313131', fontSize: '16px', lineHeight: '24px'}}>Name</TableCell>
                            <TableCell sx={{color: '#313131', fontSize: '16px', lineHeight: '24px'}}>Size</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {coiFiles.map((f: any) => <TableRow>
                            <TableCell sx={{color: '#121212', fontSize: '16px', lineHeight: '24px', fontWeight: 600}}>
                                <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                                    <DocIcon sx={{width: '24px', height: '24px', color: '#486581'}}/>
                                    <Typography sx={{color: '#121212', fontSize: '16px', lineHeight: '24px', fontWeight: 600}}>{f.key.substring(4)}</Typography>
                                </Box>
                            </TableCell>
                            <TableCell sx={{color: '#121212', fontSize: '16px', lineHeight: '24px'}}>{formatBytes(f.size)}</TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </Box>
            <Box sx={{display: 'flex', marginY: '24px', justifyContent: 'right', gap: '24px'}}>
                <Link sx={{textDecoration: 'none !important'}} href={'mailto:?subject=Help me become compliant for this Property Management Company&body=' + (riskLevel === 'HIGH'
                    ? 'https://www.respropmanagement.com/_files/ugd/2d056e_d38cf981373848d2bd1332efdba0a455.pdf'
                    : 'https://www.respropmanagement.com/_files/ugd/2d056e_5fbfcd191f1e4ee495cd568bad1301db.pdf')}>    
                    <Box sx={{padding: '12px 32px', display: 'flex', justifyContent: 'center', alignItems:'center', borderRadius: '4px', border: '2px solid #102A43',  cursor: 'pointer'}} onClick={() => {}}>
                        <Typography sx={{color: '#102A43', fontSize: '16px', lineHeight: '24px', fontWeight: 600}}>Email Requirements to my Broker</Typography>
                    </Box>
                </Link>
                <Box sx={{padding: '12px 32px', display: 'flex', justifyContent: 'center', alignItems:'center', background: '#243B53', borderRadius: '8px', cursor: 'pointer'}} onClick={() => setShowUploadNewFile(true)}>
                    <Typography sx={{color: '#fff', fontSize: '16px', lineHeight: '24px', fontWeight: 600}}>Upload New Files</Typography>
                </Box>
            </Box>
        </Container>}
        {showDetails && <Container>
            <Box sx={{marginTop: '40px', display: 'flex', gap: '8px', cursor: 'pointer'}} onClick={() => setShowDetails(null)}>
                <ArrowLeft sx={{width: '24px', height: '24px'}}/>
                <Typography sx={{fontSize: '20px', lineHeight: '24px', color: '#102A43', fontWeight: 600}}>Back to Dashboard</Typography>
            </Box>
            <Box sx={{marginTop: '32px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography sx={{fontSize: '32px', lineHeight: '36px', fontWeight: 500, color: '#272937'}}>{showDetails.id === 'MVA' ? 'Master Vendor Agreement' : showDetails?.name}</Typography>
                {showDetails.id === 'CF' && 
                    <Box sx={{padding: '4px 16px', borderRadius: '16px', background: showDetails.status === 'COMPLIANT' ? '#199473' : showDetails.status === 'EXPIRING' ? '#DE911D' : '#AB091E'}}>
                        <Typography sx={{color: '#fff', fontSize: '16px', lineHeight: '24px', fontWeight: 600}}>
                            {showDetails.status === 'COMPLIANT' ? `Payment Confirmed` : showDetails.status === 'EXPIRING' ? 'Fee Renewing Soon' : 'Payment Needed'}
                        </Typography>
                    </Box>
                }
                {showDetails.id === 'PY' && 
                    <Box sx={{padding: '4px 16px', borderRadius: '16px', background: showDetails.status === 'COMPLIANT' ? '#199473' : showDetails.status === 'EXPIRING' ? '#DE911D' : '#AB091E'}}>
                        <Typography sx={{color: '#fff', fontSize: '16px', lineHeight: '24px', fontWeight: 600}}>
                            {showDetails.status === 'COMPLIANT' ? `Account Found` : 'Account Not Found'}
                        </Typography>
                    </Box>
                }
            </Box>
            <Box sx={{marginTop: '16px'}}>
                <Typography sx={{fontSize: '16px', lineHeight: '24px', color: '#272937BF'}}>{showDetails?.cDescription}</Typography>
            </Box>

            {showDetails.id !== 'CF' && showDetails.id !== 'PY' && showDetails.id !== 'MVA' && <>
                <Box sx={{display:'flex', flexDirection: 'row', gap: '32px', marginTop: '48px', flexWrap: 'wrap'}}>
                    <Box sx={{minWidth: isMobile ? '100%' : '48%', display: 'flex', flexDirection: 'column', gap: '24px'}}>
                        <Box sx={{display:'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                            <Box sx={{fontSize: '24px', fontWeight: 500, lineHeight: '32px', color: '#272937'}}>Coverage Requirements</Box>
                            <Link href={
                                    riskLevel === 'HIGH'
                                        ? 'https://www.respropmanagement.com/_files/ugd/2d056e_d38cf981373848d2bd1332efdba0a455.pdf'
									    : 'https://www.respropmanagement.com/_files/ugd/2d056e_5fbfcd191f1e4ee495cd568bad1301db.pdf'
                                } target="_blank"><Box sx={{color: '#127FBF', fontSize: '16px', fontWeight: 500, lineHeight: '32px'}}>View Sample COI</Box></Link>
                        </Box>
                        <TableContainer sx={{borderRadius: '16px', background: '#fff', boxShadow: '0px 6px 24px 0px rgba(39, 41, 55, 0.10)'}}>
                            <Table>
                                {showDetails.id === 'GL' && <TableBody>
                                    <StyledTableRow about="title">
                                        <StyledTableCell component="td" scope="row">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>General Aggregate</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>$1,000,000</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="td" scope="row">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>Each Occurrence</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>$1,000,000</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="td" scope="row">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>Personal & Adv Injury</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>$1,000,000</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="td" scope="row">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>Products - Comp/Op Agg</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>$1,000,000</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>}
                                {showDetails.id === 'WC' && <TableBody>
                                    <StyledTableRow about="title">
                                        <StyledTableCell component="th" scope="row">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>Each Accident</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>$100,000</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>Disease EA Employee</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>$100,000</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>Disease Policy Limit</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>$500,000</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>}
                                {showDetails.id === 'CA' && <TableBody>
                                    <StyledTableRow about="title">
                                        <StyledTableCell component="th" scope="row">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>Combined Single Limit (ea)</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>{riskLevel === 'HIGH' ? '$1,000,000' : '$500,000'} or</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>Bodily Injury (per person)</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>{riskLevel === 'HIGH' ? '$500,000' : '$100,000'}</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>Bodily Injury (per accident)</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>{riskLevel === 'HIGH' ? '$500,000' : '$300,000'}</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>Property Damage (per accident)</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>{riskLevel === 'HIGH' ? '$500,000' : '$100,000'}</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>}
                                {showDetails.id === 'EL' && <TableBody>
                                    <StyledTableRow about="title">
                                        <StyledTableCell component="th" scope="row">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>Each Occurrence</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>$2,000,000</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>Aggregate</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>$2,000,000</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>}
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box sx={{minWidth: isMobile ? '100%' : '48%', display: 'flex', flexDirection: 'column', gap: '24px'}}>
                        <Box sx={{display:'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <Box sx={{fontSize: '24px', fontWeight: 500, lineHeight: '32px', color: '#272937'}}>Your Coverage Limits</Box>
                        </Box>
                        <TableContainer sx={{borderRadius: '16px', background: '#fff', boxShadow: '0px 6px 24px 0px rgba(39, 41, 55, 0.10)'}}>
                            <Table>
                                {showDetails.id === 'GL' && <TableBody>
                                    <StyledTableRow about="title">
                                        <StyledTableCell component="td" scope="row">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>General Aggregate</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px', color: showDetails?.insurance?.status === 'COMPLIANT' || showDetails?.insurance?.status === 'MISSINGEND' ? '#272937' : '#CF1124'}}>{formatter.format(showDetails?.insurance?.aggregate ?? 0)}</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="td" scope="row">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>Each Occurrence</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px', color: showDetails?.insurance?.status === 'COMPLIANT' || showDetails?.insurance?.status === 'MISSINGEND' ? '#272937' : '#CF1124'}}>{formatter.format(showDetails?.insurance?.eachOccurence ?? 0)}</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="td" scope="row">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>Personal & Adv Injury</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px', color: showDetails?.insurance?.status === 'COMPLIANT' || showDetails?.insurance?.status === 'MISSINGEND' ? '#272937' : '#CF1124'}}>{formatter.format(showDetails?.insurance?.personalAndAdvInjury ?? 0)}</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="td" scope="row">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>Products - Comp/Op Agg</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px', color: showDetails?.insurance?.status === 'COMPLIANT' || showDetails?.insurance?.status === 'MISSINGEND' ? '#272937' : '#CF1124'}}>{formatter.format(showDetails?.insurance?.productsOrCompOrOpAgg ?? 0)}</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>}
                                {showDetails.id === 'WC' && <TableBody>
                                    <StyledTableRow about="title">
                                        <StyledTableCell component="th" scope="row">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>Each Accident</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px', color: showDetails?.insurance?.status === 'COMPLIANT' || showDetails?.insurance?.status === 'MISSINGEND' ? '#272937' : '#CF1124'}}>{formatter.format(showDetails?.insurance?.eachAccident ?? 0)}</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>Disease EA Employee</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px', color: showDetails?.insurance?.status === 'COMPLIANT' || showDetails?.insurance?.status === 'MISSINGEND' ? '#272937' : '#CF1124'}}>{formatter.format(showDetails?.insurance?.diseaseEachEmployee ?? 0)}</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>Disease Policy Limit</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px', color: showDetails?.insurance?.status === 'COMPLIANT' || showDetails?.insurance?.status === 'MISSINGEND' ? '#272937' : '#CF1124'}}>{formatter.format(showDetails?.insurance?.diseasePolicyLimit ?? 0)}</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>}
                                {showDetails.id === 'CA' && <TableBody>
                                    <StyledTableRow about="title">
                                        <StyledTableCell component="th" scope="row">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>Combined Single Limit (ea)</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px', color: showDetails?.insurance?.status === 'COMPLIANT' || showDetails?.insurance?.status === 'MISSINGEND' ? '#272937' : '#CF1124'}}>{formatter.format(showDetails?.insurance?.combinedSingleLimit ?? 0)}</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>Bodily Injury (per person)</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px', color: showDetails?.insurance?.status === 'COMPLIANT' || showDetails?.insurance?.status === 'MISSINGEND' ? '#272937' : '#CF1124'}}>{formatter.format(showDetails?.insurance?.bodilyInjuryperPerson ?? 0)}</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>Bodily Injury (per accident)</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px', color: showDetails?.insurance?.status === 'COMPLIANT' || showDetails?.insurance?.status === 'MISSINGEND' ? '#272937' : '#CF1124'}}>{formatter.format(showDetails?.insurance?.bodilyInjuryPerAccident ?? 0)}</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>Property Damage (per accident)</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px', color: showDetails?.insurance?.status === 'COMPLIANT' || showDetails?.insurance?.status === 'MISSINGEND' ? '#272937' : '#CF1124'}}>{formatter.format(showDetails?.insurance?.proprtyDamagePerAccident ?? 0)}</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>}
                                {showDetails.id === 'EL' && <TableBody>
                                    <StyledTableRow about="title">
                                        <StyledTableCell component="th" scope="row">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>Each Occurrence</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px', color: showDetails?.insurance?.status === 'COMPLIANT' || showDetails?.insurance?.status === 'MISSINGEND' ? '#272937' : '#CF1124'}}>{formatter.format(showDetails?.insurance?.eachOccurence ?? 0)}</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px'}}>Aggregate</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Typography sx={{fontSize: '16px', lineHeight: '24px', color: showDetails?.insurance?.status === 'COMPLIANT' || showDetails?.insurance?.status === 'MISSINGEND' ? '#272937' : '#CF1124'}}>{formatter.format(showDetails?.insurance?.aggregate ?? 0)}</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>}
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
                <Box sx={{marginTop: '80px', display:'flex', flexDirection: 'column', gap: '24px'}}>
                    <Box>
                        <Typography sx={{fontSize: '24px', lineHeight: '32px', fontWeight: 500, color: '#272937'}}>Endorsements That Need to be Added to Your Policy</Typography>
                    </Box>
                    <Box sx={{padding: '16px 32px', borderRadius: '8px', background: '#fff', boxShadow: '0px 6px 24px 0px rgba(39, 41, 55, 0.10)', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '8px'}}>
                        <Box sx={{display: 'flex', gap: '12px', alignItems: 'center'}}>
                            <Typography sx={{fontSize: '16px', lineHeight: '24px', fontWeight: 400, color: '#272937'}}>All coverage must be <strong>primary and non-contributory</strong></Typography>
                            <InfoIcon sx={{width: '24px', height: '24px', color: '#829AB1', cursor: 'pointer'}} aria-owns={open ? 'mouse-over-popover' : undefined} 
                                aria-haspopup="true" onMouseEnter={(event) => handleClick(event, '1')} onMouseLeave={handleClose}/>
                        </Box>
                        {(showDetails?.insurance?.status === 'MISSING' || !(showDetails?.insurance?.description.toLowerCase().indexOf('primary') >= 0 && showDetails?.insurance?.description.toLowerCase().indexOf('non-contributory') >= 0) ) ? 
                            <Box sx={{padding: '4px 16px', background: '#CF1124', borderRadius: '16px', }}>
                                <Typography sx={{color: '#FFF', fontSize: '16px', lineHeight: '24px', fontWeight: 600}}>Missing</Typography>
                            </Box>
                            :
                            <Box sx={{padding: '4px 16px', background: '#199473', borderRadius: '16px', }}>
                                <Typography sx={{color: '#FFF', fontSize: '16px', lineHeight: '24px', fontWeight: 600}}>Completed</Typography>
                            </Box>
                        }
                        
                    </Box>
                    {(showDetails.id ==='GL' || showDetails.id ==='AL') && <Box sx={{padding: '16px 32px', borderRadius: '8px', background: '#fff', boxShadow: '0px 6px 24px 0px rgba(39, 41, 55, 0.10)', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '8px'}}>
                        <Box sx={{display: 'flex', gap: '12px', alignItems: 'center'}}>
                            <Typography sx={{fontSize: '16px', lineHeight: '24px', fontWeight: 400, color: '#272937'}}>Add ResProp Management as an <strong>Additional Insured</strong></Typography>
                            <InfoIcon sx={{width: '24px', height: '24px', color: '#829AB1', cursor: 'pointer'}} aria-owns={open ? 'mouse-over-popover' : undefined} 
                                aria-haspopup="true" onMouseEnter={(event) => handleClick(event, '2')} onMouseLeave={handleClose}/>
                        </Box>
                        {showDetails?.insurance?.certificateHolder?.toLowerCase().indexOf('resprop') >= 0 && showDetails?.insurance?.additionalInsured &&
                            <Box sx={{padding: '4px 16px', background: '#199473', borderRadius: '16px', }}>
                                <Typography sx={{color: '#FFF', fontSize: '16px', lineHeight: '24px', fontWeight: 600}}>Completed</Typography>
                            </Box>
                        }
                        {(!showDetails?.insurance?.additionalInsured || !showDetails?.insurance || !showDetails?.insurance?.certificateHolder || showDetails?.insurance?.certificateHolder?.toLowerCase().indexOf('resprop') < 0) &&
                            <Box sx={{padding: '4px 16px', background: '#CF1124', borderRadius: '16px', }}>
                                <Typography sx={{color: '#FFF', fontSize: '16px', lineHeight: '24px', fontWeight: 600}}>Missing</Typography>
                            </Box>
                        }
                    </Box>}
                    {showDetails.id ==='WC' && <Box sx={{padding: '16px 32px', borderRadius: '8px', background: '#fff', boxShadow: '0px 6px 24px 0px rgba(39, 41, 55, 0.10)', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '8px'}}>
                        <Box sx={{display: 'flex', gap: '12px', alignItems: 'center'}}>
                            <Typography sx={{fontSize: '16px', lineHeight: '24px', fontWeight: 400, color: '#272937'}}>Your policy needs to have <strong>Waiver of Subrogation</strong> Included</Typography>
                            <InfoIcon sx={{width: '24px', height: '24px', color: '#829AB1', cursor: 'pointer'}} aria-owns={open ? 'mouse-over-popover' : undefined} 
                                aria-haspopup="true" onMouseEnter={(event) => handleClick(event, '3')} onMouseLeave={handleClose}/>
                        </Box>
                        {showDetails?.insurance?.waiverOfSubrogation &&
                            <Box sx={{padding: '4px 16px', background: '#199473', borderRadius: '16px', }}>
                                <Typography sx={{color: '#FFF', fontSize: '16px', lineHeight: '24px', fontWeight: 600}}>Completed</Typography>
                            </Box>
                        }
                        {(!showDetails?.insurance || !showDetails?.insurance?.waiverOfSubrogation || !showDetails?.insurance?.waiverOfSubrogation) &&
                            <Box sx={{padding: '4px 16px', background: '#CF1124', borderRadius: '16px', }}>
                                <Typography sx={{color: '#FFF', fontSize: '16px', lineHeight: '24px', fontWeight: 600}}>Missing</Typography>
                            </Box>
                        }
                    </Box>}
                    <Box sx={{padding: '16px 32px', borderRadius: '8px', background: '#fff', boxShadow: '0px 6px 24px 0px rgba(39, 41, 55, 0.10)', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '8px'}}>
                        <Box sx={{display: 'flex', gap: '12px', alignItems: 'center'}}>
                            <Typography sx={{fontSize: '16px', lineHeight: '24px', fontWeight: 400, color: '#272937'}}>Attach a copy of the <strong>30 days notice of cancellation</strong></Typography>
                            <InfoIcon sx={{width: '24px', height: '24px', color: '#829AB1', cursor: 'pointer'}} aria-owns={open ? 'mouse-over-popover' : undefined} 
                                aria-haspopup="true" onMouseEnter={(event) => handleClick(event, '4')} onMouseLeave={handleClose}/>
                        </Box>
                        {showDetails?.insurance?.description?.toLowerCase().indexOf('cancellation') >= 0 &&
                            <Box sx={{padding: '4px 16px', background: '#199473', borderRadius: '16px', }}>
                                <Typography sx={{color: '#FFF', fontSize: '16px', lineHeight: '24px', fontWeight: 600}}>Completed</Typography>
                            </Box>
                        }
                        {(!showDetails?.insurance || !showDetails?.insurance.description || showDetails?.insurance?.description?.toLowerCase().indexOf('cancellation') < 0) &&
                            <Box sx={{padding: '4px 16px', background: '#CF1124', borderRadius: '16px', }}>
                                <Typography sx={{color: '#FFF', fontSize: '16px', lineHeight: '24px', fontWeight: 600}}>Missing</Typography>
                            </Box>
                        }
                    </Box>
                </Box>
                <Box sx={{height: '1px', background: '#D9E2EC', marginY: '32px'}}></Box>
                <Box sx={{padding: '20px 32px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderRadius: '8px', background: 'rgba(255, 255, 255, 0.20)', boxShadow: '0px 4px 6px 0px rgba(16, 42, 67, 0.06) inset, 0px 3px 4px 0px rgba(36, 59, 83, 0.08)', flexWrap: 'wrap', rowGap: '8px'}}>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                        <Box>
                            <Typography sx={{color: '#0D0D0D', fontSize: '16px', fontWeight: 600, lineHeight: '24px'}}>Get Covered for as low as $48 with InsurePro</Typography>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center', verticalAlign: 'middle', flexWrap: 'wrap'}}>
                            <Typography sx={{color: 'rgba(39, 41, 55, 0.75))', fontSize: '14px', lineHeight: '20px'}}>Some of the Carrier We Quote With</Typography>
                            <Box sx={{height: '30px', width: '1px', background: '#BCCCDC'}}></Box>
                            <Box sx={{display: 'flex', flexDirection: 'row', gap: '12px'}}>
                                <Box sx={{width: '30px', height: '30px'}}><img src="/liberty-c.png" width={'30px'}/></Box>
                                <Box sx={{width: '30px', height: '30px'}}><img src="/nationwide-c.png" width={'30px'}/></Box>
                                <Box sx={{width: '30px', height: '30px'}}><img src="/travellers-c.png" width={'30px'}/></Box>
                                <Box sx={{width: '30px', height: '30px'}}><img src="/progressive-c.png" width={'30px'}/></Box>
                                <Box sx={{width: '30px', height: '30px'}}><img src="/employers-c.png" width={'30px'}/></Box>
                                <Box sx={{width: '30px', height: '30px'}}><img src="/hiscox-c.png" width={'30px'}/></Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{padding: '8px 32px', borderRadius: '8px', border: '2px solid #102A43', cursor: 'pointer'}}>
                        <Link href='https://web.insurepro.com'>
                            <Typography sx={{color: '#102A43', fontSize: '16px', lineHeight: '24px', fontWeight: '600'}}>Get Covered</Typography>
                        </Link>
                    </Box>
                </Box>
                <Box sx={{marginTop: '80px', display: 'flex', flexDirection: 'column', gap: '24px'}}>
                    <Typography sx={{color: '#0D0D0D', fontSize: '24px', lineHeight: '32px'}}>Your Document(s) on File</Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{color: '#313131', fontSize: '16px', lineHeight: '24px'}}>Name</TableCell>
                                <TableCell sx={{color: '#313131', fontSize: '16px', lineHeight: '24px'}}>Size</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {coiFiles.map((f: any) => <TableRow>
                                <TableCell sx={{color: '#121212', fontSize: '16px', lineHeight: '24px', fontWeight: 600}}>{f.key.substring(4)}</TableCell>
                                <TableCell sx={{color: '#121212', fontSize: '16px', lineHeight: '24px'}}>{formatBytes(f.size)}</TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </Box>
                <Box sx={{display: 'flex', marginY: '48px', justifyContent: 'right', gap: '24px'}}>
                    <Link sx={{textDecoration: 'none !important'}} href={'mailto:?subject=Help me become compliant for this Property Management Company&body=' + (riskLevel === 'HIGH'
                                ? 'https://www.respropmanagement.com/_files/ugd/2d056e_d38cf981373848d2bd1332efdba0a455.pdf'
                                : 'https://www.respropmanagement.com/_files/ugd/2d056e_5fbfcd191f1e4ee495cd568bad1301db.pdf')}>
                        <Box sx={{padding: '12px 32px', display: 'flex', justifyContent: 'center', alignItems:'center', borderRadius: '4px', border: '2px solid #102A43',  cursor: 'pointer'}} onClick={() => {}}>
                            <Typography sx={{color: '#102A43', fontSize: '16px', lineHeight: '24px', fontWeight: 600}}>Email Requirements to my Broker</Typography>
                        </Box>
                    </Link>
                    <Box sx={{padding: '12px 32px', display: 'flex', justifyContent: 'center', alignItems:'center', background: '#243B53', borderRadius: '8px', cursor: 'pointer'}} onClick={() => setShowUploadNewFile(true)}>
                        <Typography sx={{color: '#fff', fontSize: '16px', lineHeight: '24px', fontWeight: 600}}>Upload New Files</Typography>
                    </Box>
                </Box>
            </>}
            {showDetails.id === 'CF' && <>
                <Box sx={{marginTop: '40px'}}>
                    <Typography sx={{fontSize: '24px', fontWeight: 500, lineHeight: '32px', color: '#272937'}}>Status</Typography>
                </Box>
                <Box sx={{marginTop: '24px', background: '#fff', boxShadow: '0px 4px 8px 2px rgba(0, 0, 0, 0.15)', borderRadius: '8px'}}>
                    <Box sx={{paddingY: '24px', paddingX: '32px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                            <Typography sx={{fontWeight: 600, fontSize: '16px', lineHeight: '24px', color: '#000'}}>{showDetails.title1}</Typography>
                            <Typography sx={{fontSize: '16px', lineHeight: '24px', color: 'rgba(39, 41, 55, 0.75)'}}>{showDetails.description1}</Typography>
                        </Box>
                        {showDetails.btnTxt ? 
                            <form action="/api/payments/create-checkout-session" method="POST">
                                {/* <Box onClick={payAnnualFee} sx={{paddingX: '16px', paddingY: '10px', background: '#243B53', borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}}>
                                    <Typography sx={{fontSize: '16px', lineHeight: '24px', fontWeight: 600, color: '#fff'}}>{showDetails.btnTxt}</Typography>
                                </Box> */}
                                <ButtonCustom
                                    type="submit"
                                    id="submit"
                                    className="bigButton"
                                    round={'non-full'}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                >
                                    {showDetails.btnTxt}
                                </ButtonCustom>
                                <input type="hidden" id="vendorId" name="vendorId" value={vendorDetails?._id} />
                            </form>
                            :
                            <Typography sx={{fontSize: '16px', lineHeight: '24px', fontWeight: 600}}>$99</Typography>
                        }
                    </Box>
                </Box>
            </>}
            {showDetails.id === 'MVA' && <>
                <Box sx={{marginTop: '40px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Typography sx={{fontSize: '24px', fontWeight: 500, lineHeight: '32px', color: '#272937'}}>Document</Typography>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                    {vendorDetails.externalMsa ? 
                        <object data="https://s3.us-east-2.amazonaws.com/prod.resprop-coi/external_msa.pdf" type="application/pdf" width="100%" height="700px">
                            <p>Alternative text - include a link <a href="https://s3.us-east-2.amazonaws.com/prod.resprop-coi/external_msa.pdf">to the PDF!</a></p>
                        </object> : 
                        <object data="https://s3.us-east-2.amazonaws.com/prod.resprop-coi/ResProp_Vendor_MSA.pdf" type="application/pdf" width="100%" height="700px">
                            <p>Alternative text - include a link <a href="https://s3.us-east-2.amazonaws.com/prod.resprop-coi/ResProp_Vendor_MSA.pdf">to the PDF!</a></p>
                        </object>
                    }
                </Box>
                <Box sx={{marginTop: '44px'}}>
                    <div className="inputWrap checkboxLabelMuted">
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                value={formValues.masterServiceTerm}
                                control={
                                    <Checkbox
                                        {...register('masterServiceTerm')}
                                        checked={formValues.masterServiceTerm}
                                        onChange={(e, v) => handleChange(e, 'masterServiceTerm', v)}
                                        sx={{
                                            color: errors.masterServiceTerm ? 'red' : '#27293759',
                                        }}
                                    />
                                }
                                label="I acknowledge receipt of and agree to the above terms. I understand that violating this agreement will result in termination of approval to work for ResProp Management Company."
                                labelPlacement="end"
                            />
                        </FormGroup>
                    </div>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '20px', marginBottom: '80px'}}>
                    <SaveAndContinue onClick={handleSubmit(goNext)} buttonTitle="Sign and Submit" />
                </Box>
                
            </>}
            {showDetails.id === 'PY' && <>
                <Box sx={{marginTop: '40px'}}>
                    <Typography sx={{fontSize: '24px', fontWeight: 500, lineHeight: '32px', color: '#272937'}}>Whats Next</Typography>
                </Box>
                {showDetails.status === 'COMPLIANT' && <Box sx={{marginTop: '24px', background: '#fff', boxShadow: '0px 4px 8px 2px rgba(0, 0, 0, 0.15)', borderRadius: '8px'}}>
                    <Box sx={{paddingY: '24px', paddingX: '32px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', rowGap: '8px', alignItems: 'center'}}>
                        <Typography sx={{fontSize: '16px', lineHeight: '24px', color: '#272937'}}>Be sure you have downloaded the PayUp app to access your funds.</Typography>
                        <Box sx={{display: 'flex', gap: '8px'}}>
                            <Link href="https://apps.apple.com/us/app/payup-app/id1611441692" target="_blank">
                                <Box sx={{borderRadius: '6px', border: '1px solid rgba(39, 41, 55, 0.35)', background: 'white', padding: '8px', display: 'flex', flexDirection: 'row', gap: '4px' }}>
                                    <AppStore/>
                                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                        <Typography sx={{fontSize: '8px', lineHeight:' 9.5px', color: '#272937BF'}}>Download on</Typography>
                                        <Typography sx={{fontSize: '16px', lineHeight: '22px', fontWeight: 600, color: '#272937'}}>AppStore</Typography>
                                    </Box>
                                </Box>
                            </Link>
                            <Link href="https://play.google.com/store/apps/details?id=com.rex.payup" target="_blank">
                                <Box sx={{borderRadius: '6px', border: '1px solid rgba(39, 41, 55, 0.35)', background: 'white', padding: '8px', display: 'flex', flexDirection: 'row', gap: '4px' }}>
                                    <PlayStore/>
                                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                        <Typography sx={{fontSize: '8px', lineHeight:' 9.5px', color: '#272937BF'}}>Download on</Typography>
                                        <Typography sx={{fontSize: '16px', lineHeight: '22px', fontWeight: 600, color: '#272937'}}>Google Play</Typography>
                                    </Box>
                                </Box>
                            </Link>
                        </Box>
                    </Box>
                </Box>}
                {showDetails.status !== 'COMPLIANT' && <>
                    <Box sx={{marginTop: '24px', background: '#fff', boxShadow: '0px 4px 8px 2px rgba(0, 0, 0, 0.15)', borderRadius: '8px'}}>
                        <Box sx={{paddingY: '24px', paddingX: '32px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', rowGap: '8px', alignItems: 'center'}}>
                            <Typography sx={{fontSize: '16px', lineHeight: '24px', color: '#272937'}}>Download the PayUp App, or go to their website by clicking <Link href="https://app.payup.com" target="_blank">Here</Link></Typography>
                            <Box sx={{display: 'flex', gap: '8px'}}>
                                <Link href="https://apps.apple.com/us/app/payup-app/id1611441692" target="_blank">
                                    <Box sx={{borderRadius: '6px', border: '1px solid rgba(39, 41, 55, 0.35)', background: 'white', padding: '8px', display: 'flex', flexDirection: 'row', gap: '4px' }}>
                                        <AppStore/>
                                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                            <Typography sx={{fontSize: '8px', lineHeight:' 9.5px', color: '#272937BF'}}>Download on</Typography>
                                            <Typography sx={{fontSize: '16px', lineHeight: '22px', fontWeight: 600, color: '#272937'}}>AppStore</Typography>
                                        </Box>
                                    </Box>
                                </Link>
                                <Link href="https://play.google.com/store/apps/details?id=com.rex.payup" target="_blank">
                                    <Box sx={{borderRadius: '6px', border: '1px solid rgba(39, 41, 55, 0.35)', background: 'white', padding: '8px', display: 'flex', flexDirection: 'row', gap: '4px' }}>
                                        <PlayStore/>
                                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                            <Typography sx={{fontSize: '8px', lineHeight:' 9.5px', color: '#272937BF'}}>Download on</Typography>
                                            <Typography sx={{fontSize: '16px', lineHeight: '22px', fontWeight: 600, color: '#272937'}}>Google Play</Typography>
                                        </Box>
                                    </Box>
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{marginTop: '24px', background: '#fff', boxShadow: '0px 4px 8px 2px rgba(0, 0, 0, 0.15)', borderRadius: '8px'}}>
                        <Box sx={{paddingY: '24px', paddingX: '32px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <Typography sx={{fontSize: '16px', lineHeight: '24px', color: '#272937'}}>Create an Account with PayUp. Make sure you use the same EIN/TIN that you used originally when signing up.</Typography>
                        </Box>
                    </Box>
                    <Box sx={{marginTop: '24px', background: '#fff', boxShadow: '0px 4px 8px 2px rgba(0, 0, 0, 0.15)', borderRadius: '8px'}}>
                        <Box sx={{paddingY: '24px', paddingX: '32px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', rowGap: '8px', alignItems: 'center'}}>
                            <Typography sx={{fontSize: '16px', lineHeight: '24px', color: '#272937'}}>Once you create a PayUp account, refresh and our systems will look for your account.</Typography>
                            <Box sx={{display: 'flex', gap: '8px', cursor: 'pointer'}} onClick={() => {reloadVendorDetails();}}>
                                <Box sx={{borderRadius: '6px', border: '1px solid rgba(39, 41, 55, 0.35)', background: 'white', padding: '10px 24px' }}>
                                    <Typography sx={{fontSize: '16px', lineHeight: '24px', fontWeight: 600, color: '#243B53'}}>Refresh</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </>}
            </>}
        </Container>}
        <VendorProfile editProfile={editProfile} setEditProfile={(editProfile: boolean, reload: boolean = false) => {
            setEditProfile(editProfile);
            if(reload) {
                reloadVendorDetails();
            }
        }} vendorDetails={vendorDetails} />
        {showMessages && <VendorMessages setShowMessages={setShowMessages} showMessages={showMessages} vendorDetails={vendorDetails}/> }
        
        {paymentPopup && <PaymentStatus paymentStatus={paymentStatus || ''} go_back={() => {
            setPaymentPopup(false);
            setSearchParams({});
            reloadVendorDetails();
        }} company={'Rex'} /> }
        
        <Dialog open={showUploadNewFile} fullWidth>
            <Box p={4}>
                <Box sx={{display: 'flex', gap: '8px', cursor: 'pointer'}} onClick={() => setShowUploadNewFile(false)}>
                    <ArrowLeft sx={{width: '20px', height: '20px'}}/>
                    <Typography sx={{fontSize: '16px', lineHeight: '24px', color: '#102A43', fontWeight: 600}}>Back to Dashboard</Typography>
                </Box>
                <Box sx={{marginTop: '20px', display: 'flex', flexDirection: 'column', gap: '24px'}} px={4}>
                    <Box>
                        <Typography sx={{fontSize: '24px', lineHeight: '28px', color: '#272937'}}>Upload a File</Typography>
                    </Box>
                    {/* <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                        <Typography sx={{fontSize: '16px', lineHeight: '24px', color: '#272937'}}>What policies does this file relate to?</Typography>
                        <TextField></TextField>
                    </Box> */}
                    {fileUploading && <>
                        <Box flexDirection="column" display="flex" alignItems="center" justifyContent="center">
                            <Box py={4} display="flex" flexDirection="column" borderRadius={2} gap={1}
                                alignItems={'center'} sx={{width: '100%'}}
                            >
                                <CircularProgress />
                                <Typography>Processing File...</Typography>
                                <Typography>This may take upto a minute. Please wait...</Typography>
                            </Box>
                        </Box>
                    </>}
                    {!fileUploading && <Box flexDirection="column" display="flex" alignItems="center" justifyContent="center">
                        <Box py={3} display="flex" border={'1px dashed #627D98'} flexDirection="column" borderRadius={2} 
                            alignItems={'center'} {...getRootProps({ className: 'dropzone' })} sx={{width: '100%'}}
                        >
                            <input {...getInputProps()} />
                            <UploadRenter/>
                            <Box sx={{display: 'flex'}}>
                                <Typography sx={{fontSize: '16px', lineHeight: '24px', fontWeight: 600, color: '#0D0D0D'}}>Drag and drop or &nbsp;</Typography><Typography sx={{fontSize: '16px', lineHeight: '24px', fontWeight: 600, color: '#1992D4'}}>browse files</Typography>
                            </Box>
                            <Typography sx={{fontSize: '12px', lineHeight: '16px', color: '#0D0D0D', marginTop: '4px'}}>Max File Size is 10MB</Typography>
                        </Box>
                    </Box>}
                </Box>
            </Box>
        </Dialog>
        <Popover
            id="mouse-over-popover"
            sx={{
                pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onClose={handleClose}
            disableRestoreFocus
            hideBackdrop={true}
        >
            <Box sx={{p: 2, maxWidth: '327px', display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <Typography sx={{color: '#272937', fontSize: '16px', lineHeight: '24px', fontWeight: 600}}>{popoverTitle}</Typography>
                <Typography sx={{color: '#272937', fontSize: '16px', lineHeight: '24px'}}>{popoverText}</Typography>
            </Box>
        </Popover>
    </>
}